:root{
  --amplify-primary-color	: #0f75bc;
  --amplify-primary-tint: #1f6ba1;
  --amplify-primary-shade: #1f6ba1;

}

*{
  font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
/* html, body, #outer {
  height:100%;
  margin:0;
  box-sizing:border-box;
}

#outer{
  display:flex;   
  flex-direction:column;
}

#inner-up {
  width:100%;
  height:auto;
}

#inner-down {
  flex:1 1 auto;
  background:tomato;
  overflow:auto; 
} */

/* h1 { font-family: Quicksand;
   font-size: 24px;
   font-style: normal;
   font-variant: normal;
   font-weight: 700;
   line-height: 26.4px;
} 
h3 { 
  font-family: Quicksand;
  font-size: 14px;
  font-style: normal;
  font-variant: normal; font-weight: 700; line-height: 15.4px; } 
  
p { 
  font-family: Quicksand; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 20px; 
} 
blockquote { font-family: Quicksand; font-size: 21px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 30px; } pre { font-family: Quicksand; font-size: 13px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 18.5714px; } */
.nav-tabs .nav-item .active{
    color: 'black';
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}

.dark-theme-tab{
    background-color: rgb(43, 105,149);  
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
    font-size: 0.8em;
}

.border-bottom-dark{
  /* border-bottom: 1px solid #5d5e5f */
  border-bottom: 1px solid rgb(43, 105,149);
  font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}

.nav-sm-item .active{
    font-weight: bold;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}

.nav-sm-item .active .text-dark{
    background-color: 'black';
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}

.dark-active {
    border-bottom: 1px solid transparent !important;
    border-bottom-color: #fed83f !important; 
    color: #fed83f !important;
    /*font-weight: 900;*/
    font-weight: 0.8em;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}

.nav-tabs > .nav-sm-item > a:hover {
    border-bottom: 1px solid transparent !important;
    /* border-bottom-color: #fed83f !important;  */
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    /*font-weight: 900;*/
    /* border:0; */
    border-color: rgb(43, 105,149);
    color: #fed83f !important;
}

.nav-tabs > .nav-sm-item > a:focus {
    border-bottom: 1px solid transparent !important;
    border-bottom-color: #fed83f !important; 
    /*font-weight: 900;*/
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    /* border:0; */
    border-color: rgb(43, 105,149);
    color: #fed83f !important;
}

.dropdown-item > a{
    color: black
}

.dropdown > .nav-link:hover{
    color: #fed83f!important;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    font-weight: 0.8em;
}

.react-datepicker__month-container{
    background: rgb(43, 105,149);
    color: white;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    font-size: 0.8em;
}

.react-datepicker-year-header {
    background: rgb(43, 105,149) !important;
    color: white !important;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    font-size: 0.8em;
}

.react-datepicker__input-container > input {
    position: relative;
    background-color: rgb(43, 105,149);
    font-size: 0.8em;
    /* border: 0px; */
    /*border-bottom: thin solid white;*/
    color: white;
    width: 85px;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}

.react-datepicker__input-container > input:hover {
    cursor: pointer;
}
/* .active {
    color:lavenderblush !important
} */
.navbar-custom {
    background-color: rgb(32, 72,99);
    bottom: 0 !important; 
}
.navbar-custom-1 {
    background-color: rgb(43, 105,149);
    margin:(0,0,0,0);
    padding:0;
}
nav .navbar-nav li a{
    color: white;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
    font-weight: bold;
    font-size:0.8em;
    bottom:0;

}
nav .navbar-nav li a:hover, nav .navbar-nav li a:focus{
        /* border-bottom: 1px solid #fed83f!important; */
        color: #fed83f!important;
        font-weight:1000; 
        cursor: pointer;
}

.dark-active {
    border-bottom: 1px solid transparent !important;
    border-bottom-color: #fed83f !important; 
    color: #fed83f !important;
    font-size:0.8em;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}
nav .navbar-nav-1 li a{
    color: white !important;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
    font-size:0.8em;
}
nav .navbar-nav-1 li a:hover, nav .navbar-nav-1 li a:focus{
        /* border-bottom: 1px solid #fed83f!important; */
        color: #fed83f!important;
        font-weight:1000; 
        cursor: pointer;
}
nav .navbar-nav-1 span.poweredby {
    position: absolute;
    padding-right: 10px;
    right: 0;
}
.dropdown-menu {
    background-color: rgb(32, 72,99) !important;
}
.dropdown-menu > li > a:hover,  .dropdown-menu > li > a:focus{
    background-color: rgb(32, 72,99) !important;
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover{
    background-color: rgb(32, 72,99) !important;
}
.dropdown-item > a:hover{
    /* border-bottom: 1px solid #fed83f!important; */
    color: #fed83f!important;
    font-weight:1000; 
    cursor: pointer;
    background-color: rgb(32, 72,99) !important;
}
 
/* Added Css */
.navbar-item-hover:hover{
    /* border-bottom: 1px solid #fed83f!important; */
	color: #fed83f!important;
    font-weight:1000; 
    cursor: pointer;   
}

.is-active {
    border-bottom: 1px solid #fed83f !important;
    color: #fed83f!important;
    font-weight:1000;
}